.input-base {
    width: 100%;
    font-family: NanumSquareNeo-Variable, sans-serif;
    padding: 12px 20px;
    box-sizing: border-box;
    background: #444444;
    color: #cccccc;
    border: 2px solid #646464;
    border-radius: 4px;
    font-size: 12px;
    margin: 0.5rem;
    white-space: nowrap;
}

.input-base:focus {
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.66);
    box-shadow: 0px 0 6px rgba(255, 255, 255, 0.5);
}

.input-base[aria-description="condition"] {
    padding: 6px 12px;
}

.input-base[aria-description="condition-phone"] {
    padding: 6px 12px;

}

.input-base[type="date"]::before {
    content: attr(data-placeholder);
    width: 100%;
}

.input-base[type="date"]:focus::before,
.input-base[type="date"]:valid::before{
    display: none;
}

@media (max-width: 768px) {
    .input-base {
        font-size: 12px;
        padding: 6px 8px;
    }
    .input-base[aria-description="condition"] {
        font-size: 12px;
        padding: 6px 8px;
    }
    .input-base[aria-description="condition-phone"] {
        font-size: 12px;
        padding: 6px 8px;
    }
}

