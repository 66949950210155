.alert-body {
    position: absolute;
    top: 7.5%;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    z-index: 99;
}

.alert-body-full {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.33);
    z-index: 99;
}

.alert-form-full {
    width: auto;
    height: auto;
    padding: 8px;
    font-size: 14px;
    border-radius: 8px;
    background: azure;
    border: 1px solid rgba(0, 0, 139, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.alert-form {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-size: 14px;
    border-radius: 8px;
    background: azure;
    border: 1px solid rgba(0, 0, 139, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    color: black;
}

.icon {
    margin: 4px;
    font-size: 16px;
}

.red {
    color: #ff7a7a;
}

.yellow {
    color: #ecd042;
}
