@font-face {
  font-family: 'NanumSquareNeo-Variable';
  src: url('./fonts/NanumSquareNeo-cBd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Giants-Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/Giants-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NanumGothicCoding-Bold'; /* 이 이름으로 폰트를 참조합니다. */
  src: url("./fonts/NanumGothicCoding-Bold.ttf") format('truetype'); /* 폰트 파일의 위치를 지정합니다. */
}

@font-face {
  font-family: 'NanumGothicCoding-Regular'; /* 이 이름으로 폰트를 참조합니다. */
  src: url("./fonts/NanumGothicCoding-Regular.ttf") format('truetype'); /* 폰트 파일의 위치를 지정합니다. */
}

@font-face {
  font-family: 'SUITE-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/SUITE-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

html {
  width: 100%;
  height: 100%;
  background-color: rgb(59, 59, 59);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: NanumSquareNeo-Variable, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* 스크롤바의 트랙(바탕) 스타일 */
::-webkit-scrollbar {
  width: 12px; /* 스크롤바의 너비 */
  height: 8px;
  background-color: transparent; /* 스크롤바 트랙의 배경색 */
}

/* 스크롤바의 핸들(움켜잡는 부분) 스타일 */
::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: rgb(213, 213, 213); /* 스크롤바 핸들의 배경색 */
  border-radius: 6px; /* 스크롤바 핸들의 모서리를 둥글게 만듭니다. */
  border: 1px solid #b9b9b9; /* 핸들 주변에 경계선을 추가합니다. */
}

/* 스크롤바의 핸들이 호버될 때 스타일 */
::-webkit-scrollbar-thumb:hover {
  background-color: #cbcbcb; /* 스크롤바 핸들의 배경색을 변경합니다. */
}
