.spinner-container {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11;
}

.spinner-container-trans {
    background: transparent;
    z-index: 11;
}

@media (max-width: 768px) {
    .spinner {
        width: 80px;
        height: 80px;
    }
    .spinner-container-trans {
        min-height: 200px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .spinner {
        width: 140px;
        height: 140px;
    }
    .spinner-container-trans {
        min-height: 300px;
    }
}

@media (min-width: 1025px) {
    .spinner {
        width: 200px;
        height: 200px;
    }
    .spinner-container-trans {
        min-height: 400px;
    }
}
