input {
  margin: 0.5rem 0;
  font-family: NanumSquareNeo-Variable, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

button {
  font-family: NanumSquareNeo-Variable, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

selector {
  font-family: NanumSquareNeo-Variable, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.column {
  display: flex;
  flex-direction: column;
}

.logout-button {
  background: linear-gradient(to top right, #a96b48 15%, #ffa962 70%);
  font-family: NanumSquareNeo-Variable , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border-radius: 2rem;
  font-size: 10px;
  padding: 0.5rem 1rem;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.3);
}

.upload-button {
  background: linear-gradient(to top right, #383838 15%, #606060 70%);
  font-family: NanumSquareNeo-Variable , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border-radius: 0.5rem;
  font-size: 10px;
  padding: 0.5rem 1rem;
  color: white;
  margin: 0.5rem;
}

.upload-button:hover {
  background: linear-gradient(to top right, #4a4a4a 15%, #787878 70%);
}

.upload-button:active {
  background: linear-gradient(to top right, #303030 15%, #505050 70%);
}

.reg-schedule-button {
  background: linear-gradient(to top right, #6070c2 15%, #546ade 70%);
  border-radius: 0.5rem;
  font-size: 10px;
  padding: 0.5rem 1rem;
  color: white;
  margin: 0.5rem;
  border: 1px solid #00000050;
}

.reg-schedule-button:hover {
  background: linear-gradient(to top right, #7181d3 15%, #6578e9 70%);
  border: 1px solid #00000080;
}

.reg-schedule-button:active {
  background: linear-gradient(to top right, #4f61b2 15%, #4557c7 70%);
  border: 1px solid #000000;
}

.download-button {
  box-sizing: border-box;
  border: none;
  background: #a1a1a1;
  color: white;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  transition: all 0.3s ease-in-out;
}

.download-button:hover {
  background: #808080;
}

.download-button:active {
  background: #737373;
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profiles-container {
  display: none;
}

.profiles > div, .profiles > button{
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}

.user-info-element:hover > .profiles-container {
  display: flex;
}

.profiles-container:hover {
  display: flex;
}

.user-info-element:hover > .profiles-container > .profiles > div,
.user-info-element:hover > .profiles-container > .profiles > button {
  animation: slideInFromBottom 0.5s ease-in-out forwards;
}

.user-info-element:hover > .profiles-container > .profiles > div:nth-child(1) { animation-delay: 0s; }
.user-info-element:hover > .profiles-container > .profiles > .seperator-half-top { animation-delay: 0.1s; }
.user-info-element:hover > .profiles-container > .profiles > div:nth-child(3) { animation-delay: 0.2s; }
.user-info-element:hover > .profiles-container > .profiles > .seperator-half { animation-delay: 0.3s; }
.user-info-element:hover > .profiles-container > .profiles > button { animation-delay: 0.4s; }

.logout-button:hover {
  background: linear-gradient(to top right, #a96b48 15%, #ffe8d7 70%);
}

.blue {
  color: #1f95c7;
}

.row {
  display: flex;
  flex-direction: row;
}

.white {
  color: white;
}

.align-center {
  align-items: center;
  justify-content: center;
}

.align-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.align-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.align-left-center {
  align-items: flex-start;
  justify-content: center;
}

.align-center-left {
  align-items: center;
  justify-content: flex-start;
}

.align-right-center {
  align-items: flex-end;
  justify-content: center;
}

.align-center-right {
  align-items: center;
  justify-content: flex-end;
}

.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: start;
}

.text-align-right{
  text-align: end;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-size-width {
  width: 100%;
}

.full-size-height {
  height: 100%;
}

.grow-size{
  flex-grow: 1;
}

.between {
  justify-content: space-between;
}

.header {
  margin-bottom: 3%;
}

.footer {
  margin-top: 3%;
}

.normal-notice {
  font-size: 12px;
}

.muted-notice {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.normal-info {
  font-size: 14px;
}

.medium-info {
  font-size: 16px;
}

.extra-info {
  font-size: 18px;
}

.normal-title {
  font-size: 18px;
}

.medium-title {
  font-size: 22px;
}

.extra-title {
  font-family: SUITE-Regular;
  font-size: 28px;
}

.bold {
  font-weight: bold;
}

.non-decoration {
  text-decoration: none;
}

.less-important-info {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}

.doughnut-value-label-top {
  font-size: 12px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.doughnut-value-label-right {
  font-size: 12px;
  position: absolute;
  top: calc(50% - 12px);
  right: -12px;
  transform: translateY(-50%);
}
.doughnut-value-label-bottom {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.doughnut-value-label-left {
  font-size: 12px;
  position: absolute;
  top: calc(50% - 12px);
  left: -12px;
  transform: translateY(-50%);
}

.login-form {
  width: 400px;
  padding: 28px;
  margin: 2rem;
  border-radius: 2%;
}

.input-container > input {
  background: transparent;
  border: none;
  font-size: 15px;
  padding: 1rem;
  margin: 0;
  color: #cccccc;
}

.login-button-base {
  width: 100%;
  font-family: NanumSquareNeo-Variable, sans-serif;
  background-color: #252525;
  color: white;
  border-radius: 8px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
}

.intro {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  color: black;
}

.checkbox-label {
  display: inline-block;
  width: 20px; /* 사용자 정의 너비 */
  height: 20px; /* 사용자 정의 높이 */
  min-width: 20px; /* 사용자 정의 너비 */
  min-height: 20px; /* 사용자 정의 높이 */
  background-color: #fff; /* 배경 색상 */
  margin-right: 10px; /* 텍스트와의 간격 */
  cursor: pointer; /* 마우스 오버 시 커서 변경 */
  /* 추가 스타일링 (예: border-radius for rounded corners) */
  border-radius: 4px; /* 둥근 모서리 */
  position: relative;
}

.custom-checkbox:disabled + .checkbox-label {
  cursor: auto; /* 또는 다른 스타일 */
}

.custom-checkbox:checked:disabled + .checkbox-label {
  background-color: #bbbbbb; /* 체크 시 배경 색상 변경 */
  /* 체크 표시를 위한 스타일링, 예를 들어, background-image 사용 가능 */
}

.custom-checkbox:checked + .checkbox-label:after {
  content: ""; /* 가상 요소의 내용 */
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* 체크 표시 모양 */
}

.custom-checkbox:checked + .checkbox-label {
  background-color: #25abf6; /* 체크 시 배경 색상 변경 */
  /* 체크 표시를 위한 스타일링, 예를 들어, background-image 사용 가능 */
}

.label {
  color: black;
}

.seperator {
  margin: 1rem 0px;
}

.seperator-bottom {
  margin: 0 0 1rem 0;
}

.seperator-top {
  margin: 1rem 0 0 0;
}

.seperator-horz {
  margin: 0px 1rem;
}

.grid-form {
  border: #cbcbcb 1px solid;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.grid-title {
  box-sizing: border-box;
  border-bottom: rgba(215, 215, 215, 0.88) 1px solid;
  padding: 0.5rem 1rem;
}

.grid-middle {
  box-sizing: border-box;
  border-bottom: rgba(215, 215, 215, 0.88) 1px solid;
  border-top: rgba(215, 215, 215, 0.88) 1px solid;
  padding: 0.5rem 1rem;
}

.grid-middle > p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}


.grid-title > p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}


.grid-p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.grid-footer {
  box-sizing: border-box;
  border-top: rgba(215, 215, 215, 0.88) 1px solid;
  padding: 0.5rem 1rem;
}

.grid-footer > div > p {
  font-size: 10px;
  margin: 0 4px;
  padding: 0;
}

.grid-container {
  box-sizing: border-box;
  padding: 0.2rem;
}

.user-info{
  z-index: 100;
  position: absolute;
  margin: 0 0 0 0.5rem;
  right: 0;
  background: linear-gradient(to top right, #b9b9b9 15%, #FFFFFF 70%);
  border-radius: 0.5rem;
  border: 1px solid #00000050;
  width: 180px;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8);
}

.category-texts > p {
  width: 100%;
  margin: 0;
  padding: 0 1rem;
  font-size: 13px;
  color: #999999;
}

.main-table-body {
  height: calc(100% - 128px);
  position: relative;
  background: #ffffff;
  margin: 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 0;
}

.table {
  white-space: nowrap;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.table-item {
  height: 100%;
}

.table-item p {
  font-size: 12px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.table-item div {
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.table-item button {
  white-space: nowrap;
}

.table-item:hover {
  background-color: #b9f0ff;
  cursor: pointer;
}

.first-column {
  border: none;
  box-sizing: border-box;
  position: sticky;
  left: 0px;
  z-index: 1;
}

.first-child {
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  position: sticky;
  left: 0px;
  z-index: 1;
  background-color: #a2a2a2;
}

.table-header {
  font-size: 13px;
  font-weight: bold;
  background-color: #ffffff;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: 1px solid #2B2B2B;
  white-space: nowrap;
}

.table-header:first-child {
  border-top-left-radius: 0.5rem;
}

.table-header:last-child {
  border-top-right-radius: 0.5rem;
}

.table-header div {
  box-sizing: border-box;
  padding: 0 0.5rem;
}


.table-header p {
  color: black;
  margin: 0.5rem;
}


.table-container-h1 {
  height: 100%;
  max-height: 100%;
  transition: height 0.3s ease-in-out;
}

.page-buttons > .page-button {

}

.page-button:active {
  background: #e8e8e8;
}

.user-info > p {
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
}

.seperator-horz-left {
  margin: 0 0 0 1rem;
}

.seperator-horz-right {
  margin: 0 1rem 0 0;
}

.seperator-half {
  margin: 0.5rem 0px;
}

.seperator-half-bottom {
  margin: 0 0 0.5rem 0;
}

.seperator-half-top {
  margin: 0.5rem 0 0 0;
}

.seperator-horz-half {
  margin: 0px 0.5rem;
}

.seperator-horz-half-left{
  margin: 0 0 0 0.5rem;
}

.seperator-horz-half-right{
  margin: 0 0.5rem 0 0;
}

.background {
  background-color: rgb(59, 59, 59);
}

.modal-close {
  background: white;
}

.modal-close:hover {
  background: #bbbbbb;
}