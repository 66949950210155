.selector-base {
    width: 100%;
    font-family: NanumSquareNeo-Variable, sans-serif;
    padding: 4px;
    background: #444444;
    box-sizing: border-box;
    border: 2px solid #646464;
    border-radius: 4px;
    font-size: 14px;
    margin: 0.5rem;
    color: #cccccc;
}

.selector-base:focus {
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.66);
    box-shadow: 0px 0 6px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
    .selector-base {
        font-size: 12px;
        margin: 4px 0;
    }
}
