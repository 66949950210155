.no-data-icon {
    font-size: 22px;
    color: #ffffff;
    margin: 0.5rem;
}

.no-data-text {
    font-size: 18px;
    color: #ffffff;
}
