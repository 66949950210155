.image-container {
    overflow: hidden;
    float: left;
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
}

.image-container video {
    width: 100%;

    object-fit: cover;
    border-radius: 0.5rem;
    border: 1px solid #00000060;
}

.image-container img {
    width: 100%;

    object-fit: cover;
    border-radius: 0.5rem;
    border: 1px solid #00000060;
}

.cctv-tree {
    /* 트리 구조의 전체 스타일을 여기에 추가합니다 */
    padding: 10px;
    color: white;
    font-size: 14px;
}

.tree-item {
    /* 각 CCTV 항목의 스타일을 여기에 추가합니다 */
    padding: 5px 10px;
    cursor: pointer;
    transition: max-height 0.3s, background-color 0.3s;
}

.tree-item:hover {
    background-color: #666; /* 호버 상태의 배경색 */
}

.tree-children {
    /* 하위 항목들에 대한 스타일을 여기에 추가합니다 */
    padding-left: 20px;
}
