.auto-input-base {
    width: auto;
    font-family: NanumSquareNeo-Variable, sans-serif;
    padding: 10px 14px;
    background: #444444;
    box-sizing: border-box;
    border: 2px solid #646464;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    margin: 0.5rem;
    color: #cccccc;
}

.auto-input-base:focus {
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.66);
    box-shadow: 0px 0 6px rgba(255, 255, 255, 0.5);
}

.suggestion-item > p {
    color: #cccccc;
    margin: 0;
    white-space: nowrap;
}


/* BaseAutocompleteInput.css 추가 스타일 */

/* 드롭다운 메뉴의 부모 컨테이너 */
div {
    position: relative;
}

/* 드롭다운 메뉴 */
.autocomplete-container > ul {
    position: absolute;
    width: calc(100% - 1rem); /* input의 마진을 고려한 너비 */
    max-height: 150px; /* 적당한 높이로 제한 */
    overflow-y: auto; /* 스크롤 가능 */
    border: 1px solid #888888;
    border-radius: 4px;
    background-color: #525252;
    z-index: 1; /* 다른 요소 위에 표시 */
    list-style-type: none; /* 목록 점 제거 */
    padding: 0; /* 내부 패딩 제거 */
    margin: 0;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
}

.autocomplete-container > ul li {
    padding: 8px 16px;
    cursor: pointer; /* 선택 가능한 항목임을 표시 */
    font-size: 13px;
    white-space: break-spaces;
}

.autocomplete-container > ul li:hover {
    background-color: #8a8a8a; /* 마우스 호버시 배경 색 변경 */
}
