.button-base {
    font-family: NanumSquareNeo-Variable, sans-serif;
    background: #444444;
    color: #cccccc;
    border-radius: 8px;
    border: none;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
}

.button-base-field {
    width: 100%;
    font-family: NanumSquareNeo-Variable, sans-serif;
    background-color: #a8d0e6;  /* 연한 파랑색 */
    color: #000000;
    border-radius: 8px;
    border: 1px solid #86b4ce;  /* 테두리를 버튼의 색상보다 약간 어둡게 설정 */
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* 입체적 효과를 위한 그림자 */
    transition: all 0.3s ease;  /* 부드럽게 색상 및 그림자 변경 효과 추가 */
    margin: 0;
}

.button-base-field:disabled {
    width: 100%;
    font-family: NanumSquareNeo-Variable, sans-serif;
    background-color: #afafaf;  /* 연한 파랑색 */
    color: #000000;
    border-radius: 8px;
    border: 1px solid #a8a8a8;  /* 테두리를 버튼의 색상보다 약간 어둡게 설정 */
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* 입체적 효과를 위한 그림자 */
    transition: all 0.3s ease;  /* 부드럽게 색상 및 그림자 변경 효과 추가 */
    margin: 0;
}

.button-base-awesome {
    background: transparent;
    color: #ffffff;
    border-radius: 8px;
    border: 2px solid #cccccc;  /* 테두리를 버튼의 색상보다 약간 어둡게 설정 */
    padding: 0.8rem;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* 입체적 효과를 위한 그림자 */
    transition: all 0.3s ease;  /* 부드럽게 색상 및 그림자 변경 효과 추가 */
    margin: 4px;
}

.button-base-awesome-field {
    width: 100%;
    background-color: #aaaaaa;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #cccccc;  /* 테두리를 버튼의 색상보다 약간 어둡게 설정 */
    padding: 0.2rem 1rem;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* 입체적 효과를 위한 그림자 */
    transition: all 0.3s ease;  /* 부드럽게 색상 및 그림자 변경 효과 추가 */
    margin: 4px;
}

.button-base-awesome-trans {
    background: transparent;
    color: #000000;
    border: none;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.3s ease;  /* 부드럽게 색상 및 그림자 변경 효과 추가 */
    margin: 4px;
}

.button-base-awesome[aria-description="attach"] {
    background: cornflowerblue;
    font-size: 18px;
    padding: 4px 2rem;
    margin: 0.5rem 1rem;
}

.button-base-awesome:hover {
    background-color: #898989;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-awesome:active {
    background-color: #808080;  /* 눌렸을 때의 색상: 조금 더 어둡게 설정 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* 그림자 위치 및 크기 조절 */
}

.button-base-awesome-trans:hover {
    background-color: #898989;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-awesome-trans:active {
    background-color: #808080;  /* 눌렸을 때의 색상: 조금 더 어둡게 설정 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* 그림자 위치 및 크기 조절 */
}


.button-base-awesome[aria-description="attach"]:hover {
    background-color: #5c89d9;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-awesome[aria-description="attach"]:active {
    background-color: #4f76bb;  /* 눌렸을 때의 색상: 조금 더 어둡게 설정 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* 그림자 위치 및 크기 조절 */
}

.button-base-field:hover {
    background-color: #86b4ce;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-field:active {
    background-color: #6a9cb2;  /* 눌렸을 때의 색상: 조금 더 어둡게 설정 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* 그림자 위치 및 크기 조절 */
    transform: translateY(2px);  /* 버튼을 아래로 약간 움직여 눌린 효과 표현 */
}

.button-base-field:disabled:hover,
.button-base-field:disabled:active {
    background-color: #afafaf; /* disabled 상태에서의 배경색 변경 */
    box-shadow: none; /* 그림자 효과 제거 */
}

.button-base-field.status-pending:hover {
    background-color: #cec086;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}


.button-base-field.status-pending:active {
    background-color: #b2a46a;  /* 눌렸을 때의 색상: 조금 더 어둡게 설정 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* 그림자 위치 및 크기 조절 */
    transform: translateY(2px);  /* 버튼을 아래로 약간 움직여 눌린 효과 표현 */
}

.button-base[aria-description="cancle"] {
    background-color: #e6a8a8;  /* 연한 파랑색 */
    color: #ffffff;
}

.button-base[aria-description="cancle"]:hover {
    background-color: #ce8686;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base[aria-description="cancle"]:active {
    background-color: #b26a6a;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-field[aria-description="cancle"] {
    background-color: #e6a8a8;  /* 연한 파랑색 */
    color: #000000;
}

.button-base-field[aria-description="cancle"]:hover {
    background-color: #ce8686;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-field[aria-description="cancle"]:active {
    background-color: #b26a6a;  /* 호버시 약간 어두운 색상으로 변경 */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);  /* 호버시 그림자 효과 강조 */
}

.button-base-field.status-pending {
    background-color: #e6e3a8;
    border: 1px solid #cec886;
}


.button-base:hover {
    background-color: #555555;
}

.button-base:active {
    background-color: #404040;
}

@media (max-width: 768px) {
    .button-base-awesome {
        font-size: 18px;
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
    }
    .button-base-awesome[aria-description="attach"] {
        background: cornflowerblue;
        font-size: 14px;
        padding: 6px 2rem;
        margin: 0.5rem 1rem;
        height: auto;
    }
}
