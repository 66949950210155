.main-body {
    width: 1920px;
    height: 100%;
    position: relative;
}

.menu-item {
    background-color: #5d5d5d;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.menu-item:hover, .menu-item:active {
    background-color: #6e6e6e; /* 마우스 오버 및 클릭 시 배경색 변경 */
    color: #ffffff; /* 마우스 오버 및 클릭 시 텍스트 색상 변경 (필요한 경우) */
}

.submenu div {
    background-color: #7c7c7c;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    font-size: 14px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.submenu div:hover, .submenu div:active {
    background-color: #8e8e8e; /* 마우스 오버 및 클릭 시 배경색 변경 */
    color: #ffffff; /* 마우스 오버 및 클릭 시 텍스트 색상 변경 (필요한 경우) */
}
